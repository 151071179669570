<template>
  <!-- 登录模块 -->
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
      <img src="@/assets/logo.png" alt="登录头部图">
      <div class="title-container">
        <h3 class="title">青训小将综合管理平台</h3>
      </div>
      <div class="content">
        <span>首次扫码登录</span>
        <span>请先验证绑定手机号信息</span>
      </div>
      <el-form-item prop="Phone">
        <el-input ref="Phone" v-model="loginForm.Phone" placeholder="手机号" name="Phone" type="text" tabindex="1" auto-complete="on" />
      </el-form-item>
      <el-form-item prop="VerifyCode">
        <el-row>
          <el-col :span='16'>
            <el-input v-model="loginForm.VerifyCode" placeholder="验证码" name="VerifyCode" tabindex="2" auto-complete="on" @keyup.enter.native="handleLogin" />
          </el-col>
          <el-col :span='6' :offset="2">
            <el-button v-if="status !== 2" class='btn-box' type="primary" @click.native.prevent="getTencentCaptcha">{{ status === 1 ?  '获取验证码' : '重新获取' }}</el-button>
            <el-button v-else class='btn-box'>{{ time }}秒</el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-button :loading="loading" type="primary" class="login-button" @click.native.prevent="handleLogin">登录</el-button>
    </el-form>
    <div class="footer-box "><span class="pr-4">Copyright 2014 - 2022 趣点科技版权所有. 备案号: </span>
      <a a href="https://beian.miit.gov.cn/" data-v-749f2458=""><span data-v-749f2458="">&nbsp;蜀ICP备20008447号&nbsp;</span></a>
    </div>
  </div>
</template>

<script>
import { sendClubTeacherPhoneBound, clubTeacherBound, getCaptcha } from '@/service/auth.js';


export default {
  name: "Login",
  data() {

    /**
     * 手机校验规则
     */
    const validatePhone = (rule, value, callback) => {
      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!value || value.trim() === '') {
        callback(new Error('请输入手机号'));
      } else if (!reg_tel.test(value.trim())) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };

    /**
     * 验证码码校验规则
     */
    const validateVerifyCode = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        callback(new Error('请输入验证码'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        Phone: '',
        VerifyCode: '',
      },
      loginRules: {
        Phone: [{ required: true, trigger: 'blur', validator: validatePhone }],
        VerifyCode: [{ required: true, trigger: 'blur', validator: validateVerifyCode }],
      },
      status: 1,  // 1是获取验证码，2是正在获取中,3是重新获取
      loading: false,
      time: 60,
    }
  },

  async created() {

  },

  methods: {
    /**
     * 点击登录
     */
    handleLogin() {
      // 校验用户名和密码
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          let res = await clubTeacherBound(this.loginForm);
          if (res.Status === 1) {
            this.$message.error('正在审核中，请耐心等待。');
            return;
          }
          this.$store.commit('set_userInfo', res);
          this.$router.push({ path: '/organizational' });
        } else {
          return false;
        }
      });
    },

    /**
     * 图形验证码
     */
    getTencentCaptcha() {
      this.$refs.loginForm.validateField('Phone', async (valid) => {
        if (valid) {
          return;
        }
        const captchaId = '2081383555'; // 腾讯滑块验证码appid
        // 生成一个滑块验证码对象
        let captcha = new TencentCaptcha(captchaId,
          async (res) => {
            // 后台校验方法
            if (res.ret === 0) {
              this.getPassword(res);
            }
          })
        // 滑块显示
        captcha.show();

      })
    },

    async getPassword(res) {
      let ret = await sendClubTeacherPhoneBound({
        Phone: this.loginForm.Phone,
        Ticket: res.ticket,
        Randstr: res.randstr
      });
      if (ret.Code === '1107') {
        this.$message.error(ret.Msg);
      } else {
        this.status = 2;
        let timer = setInterval(() => {
          this.time -= 1;
          if (this.time === 0) {
            this.status = 3;
            this.time = 60; // 重置时间
            clearInterval(timer);
          }
        }, 1000);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$bg: #fff;
$dark_gray: #889aa4;
$light_gray: #0079fe;

.login-container {
  height: 100%;
  width: 100%;
  background-color: rgba(#8fcc80, 0.2);
  overflow-y: auto;
  padding: 50px 0;
  .login-form {
    position: relative;
    width: 400px;
    height: 510px;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    background-color: $bg;
    border-radius: 6px;
    padding: 0 20px 10px;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: #21293a;
      margin: 0px auto 10px auto;
      text-align: center;
      font-weight: bold;
    }
  }
  /deep/.el-input .el-input__inner {
    height: 38px !important;
  }
  .login-button {
    width: 100%;
    margin: 5px 0 10px;
    font-size: 20px;
    height: 38px;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    display: inline-block;
    font-size: 20px;
    color: #333333;
    width: 158px;
  }
  a {
    text-decoration: none;
  }
}
p {
  font-size: 16px;
  color: #5e5e5e;
  text-align: center;
}
// 设置input的placeholder字体颜色
/deep/input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #000;
}
/deep/input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #000;
}
/deep/input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #000;
}
/deep/input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #000;
}
/deep/.el-input .el-input__inner {
  height: 38px !important;
}
.btn-box {
  width: 100%;
  height: 38px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    font-size: 14px;
    margin: 8px 0;
    font-weight: 400;
    color: #6a757e;
  }
  span:last-child {
    margin-bottom: 24px;
  }
}

img {
  display: block;
  width: 150px;
  margin: 20px auto;
}

.footer-box {
  // background-color: rgba(#8fcc80, 0.2);
  border-top: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 0;
  // position: absolute;
  position: fixed;
  bottom: 0;
  color: #5c6273;
  font-size: 14px;
  a {
    text-decoration: none;
    color: #5c6273;
  }
}
</style>
